<template>    

  <div class="main_header ">
      <div class="headContent " >
          <div class="logo_info">
            <img src="../assets/logo2.png" alt="唯美指令">
            <span>唯美指令</span>
          </div>
          <div class="head_tabs hidden-xs-only">
            <span :class="active == 1?'actives':''" @click="activeClick(1)">首页</span>
            <span :class="active == 2?'actives':''" @click="activeClick(2)">教程与技巧</span>
            <span :class="active == 3?'actives':''" @click="activeClick(3)">关于我们</span>
          </div>
          <div class="head_Search hidden-xs-only">
            <el-input
            v-model="inputSearch"
            class="w-50 m-2 inputSearch"
            placeholder="搜索热门指令"
            :prefix-icon="Search"
            />
          </div>
          <div v-if="isShow" class="mobileMenu" @click="showDrawer">
            <el-icon><Fold /></el-icon>
          </div>
      </div>

      <el-drawer
      v-model="drawer"
      title=""
      :with-header="false"
      :direction="direction"
      size="50%"
    >
      <div class="head_tabs2">
        <span :class="active == 1?'actives2':''" @click="activeClick(1)">首页</span>
        <span :class="active == 2?'actives2':''" @click="activeClick(2)">教程与技巧</span>
        <span :class="active == 3?'actives2':''" @click="activeClick(3)">关于我们</span>

        <el-input v-model="inputSearch" placeholder="搜索热门指令" style="margin-top:20px;">
          <template #append>
            <el-button :icon="Search" />
          </template>
        </el-input>
      </div>
    </el-drawer>
  </div>

  <div class="contentBox">
    <Home v-if="active == 1"/>
    <Tutorial v-if="active == 2"/>
    <About v-if="active == 3"/>
  </div>

  <div class="main_footer">
    <div class="footer_content">
      <img src="../assets/logo2.png" alt="唯美指令">
      <span>Copyright © 2019 iOS唯美指令｜备案号-3｜</span>
    </div>
  </div>

  
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Home from '@/components/home.vue';
import Tutorial from '@/components/tutorial.vue';
import About from '@/components/about.vue';
import {ref} from 'vue';
import { Calendar, Search} from '@element-plus/icons-vue'


export default {
  name: 'HomeView',
  components:{
    Home,Tutorial,About
  },
  setup(){
    return {
      Calendar, 
      Search,
      }
    },

    data() {
      return {
        active:1,
        inputSearch:'',
        isShow: false,
        fullWidth: 0,
        drawer:false,
        direction:'ltr'
      }
    },

    created(){
      
      window.addEventListener('resize', this.handleResize)//监听视口变化
    },
    mounted(){
      this.handleResize()
    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
      handleResize (event) {
        this.fullWidth = document.documentElement.clientWidth;
        console.log(this.fullWidth)
        // 页面宽度小于750px时，不显示
        if (this.fullWidth < 750) {
          this.isShow = true;
        } else {
          this.isShow = false;
        }

        console.log(this.isShow)
      },

      showDrawer(){
        this.drawer = true
      },

      activeClick(num){
        this.active = num;
        if(num == 2){
          this.$router.push({ name:'about' })
        }
        this.drawer = false
      }
    }
}
</script>

<style scoped lang="less">
  .main_header{
    background: #fff;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    .headContent {
      display: flex;
      // place-content: space-between;
      height: 100%;
      align-items: center;
      // padding:0 360px;
      .logo_info {
        display: flex;
        height: 100%;
        align-items: center;
        img {
          width: 48px;
          height: 48px;
        }
        span {
          margin-left: 10px;
          font-size: 32px;
        }
      }
      .head_tabs {
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-left:50px;
        height: 100%;
        span {
          display: block;
          height:100%;
          line-height: 80px;
          width:100px;
          cursor: pointer;
        }
        span:hover {
          color: #002BFF ;
        }
        .actives{
          color: #002BFF ;
        }
      }
      .head_Search {
        margin-left:auto;
        .inputSearch {
          width: 290px;
          height: 46px;
          line-height: 46px;
          border-radius: 8px;
          background: #eee;
        }
      }
      .mobileMenu {
        margin-left: auto;
      }
    }

   ::deep .el-drawer__body {
      padding: 0 !important;
    }
  }

  .contentBox {
    // margin-top:80px
  }
  
  .main_footer {
    height: 100px;
    background: #2F2E35;
    // padding:0 360px;
    margin-top:50px;
    .footer_content {
      display: flex;
      align-items: center;
      color: #fff;
      // font-size: 16px;
      height: 100px;
      line-height: 100px;
      
      img {
        width: 48px;
        height: 48px;
        margin-right: 20px;
      }
    }
  }

  .head_tabs2 {
    span {
      display: block;
      height: 30px;
      line-height: 30px;
      width: calc(100% - 20px);
      padding: 10px;
      border-bottom: 1px solid #eee;
    }
    .actives2 {
      background: #eee;
    }
  }
 

  
</style>
