<!-- <template>
//   <nav style="margin-top:100px">
//     <router-link to="/">Home</router-link> |
//     <router-link to="/about">About</router-link>
//   </nav>
//   <router-view/>
// </template> -->

<template>    

  <div class="main_header ">
      <div class="headContent " >
          <div class="logo_info">
            <img src="./assets/head_logo.png" alt="唯美指令">
            <span>唯美指令</span>
          </div>
          <div class="head_tabs hidden-xs-only">
            <span :class="active == 'Home'?'actives':''" @click="activeClick('Home')">首页</span>
            <span :class="active == 'Tutorial'?'actives':''" @click="activeClick('Tutorial')">教程与技巧</span>
            <span :class="active == 'About'?'actives':''" @click="activeClick('About')">关于我们</span>
          </div>
          <div class="head_Search hidden-xs-only">
            <el-input
            v-model="inputSearch"
            class="w-50 m-2 inputSearch"
            placeholder="搜索热门指令"
            @change="searchHandle"
            >
              <template #append>
                <el-button :icon="Search" @click="searchHandle"/>
              </template>
            </el-input>
          </div>
          <div v-if="isShow" class="mobileMenu" @click="showDrawer">
            <el-icon size="20" color="#7c7c7c"><Fold /></el-icon>
          </div>
      </div>

      <el-drawer
      v-model="drawer"
      title=""
      :with-header="false"
      :direction="direction"
      size="50%"
    >
      <div class="head_tabs2">
        <span :class="active == 'Home'?'actives2':''" @click="activeClick('Home')">首页</span>
        <span :class="active == 'Tutorial'?'actives2':''" @click="activeClick('Tutorial')">教程与技巧</span>
        <span :class="active == 'About'?'actives2':''" @click="activeClick('About')">关于我们</span>

        <el-input v-model="inputSearch" placeholder="搜索热门指令" style="margin-top:20px;padding:0 10px">
          <template #append>
            <el-button :icon="Search" @click="searchHandle"/>
          </template>
        </el-input>
      </div>
    </el-drawer>
  </div>

  <div class="contentBox" ref="contentBoxs">
    <!-- <Home v-if="active == 1"/> -->
    <!-- <Tutorial v-if="active == 2"/>
    <About v-if="active == 3"/> -->
    <router-view :inputText="inputSearch2" />
  </div>

  <div v-if="showFooter" class="main_footer" :class="putBottom ? 'positionB':''" >
    <div class="footer_content">
      <img src="./assets/logo2.png" alt="唯美指令">
      <span>Copyright © 2019 iOS唯美指令｜<a href="https://beian.miit.gov.cn/#/Integrated/index" style="color:#96b9ec" target="_blank" rel="noopener noreferrer">鄂ICP备19028264号-1</a>｜</span>
    </div>
  </div>

  
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Home from '@/components/home.vue';
import Tutorial from '@/components/tutorial.vue';
import About from '@/components/about.vue';
import {ref} from 'vue';
import { Calendar, Search} from '@element-plus/icons-vue'


export default {
  // name: 'HomeView',
  components:{
    Home,Tutorial,About
  },
  setup(){
    return {
      Calendar, 
      Search,
      }
    },

    data() {
      return {
        active:'Home',
        inputSearch:'',
        isShow: false,
        fullWidth: 0,
        drawer:false,
        putBottom:false,
        showFooter:false,
        direction:'ltr',
        inputSearch2:''
      }
    },

    created(){
      console.log(this.$route,'route')
      window.addEventListener('resize', this.handleResize)//监听视口变化
    },

    watch:{
      $route(newValue, oldValue){
          this.active = newValue.name;
          this.footShow()
      }
    },
    mounted(){
      this.handleResize()
      this.footShow()
    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
      handleResize (event) {
        this.fullWidth = document.documentElement.clientWidth;
        console.log(this.fullWidth)
        // 页面宽度小于750px时，不显示
        if (this.fullWidth < 750) {
          this.isShow = true;
        } else {
          this.isShow = false;
        }



      },

      //计算底部跟随内容还是置底
      footShow(){
        let that = this;
        setTimeout(() => {
            let fullHeight = document.documentElement.clientHeight;
            let contentHight = that.$refs.contentBoxs.offsetHeight;
            if (fullHeight - contentHight > 100) {
              that.putBottom = true;
            } else {
              that.putBottom = false;
            }

            that.showFooter = true
        }, 1000);
      
      },

      showDrawer(){
        this.drawer = true
      },

      //点击导航跳转页面
      activeClick(str){
        this.active = str;
        this.showFooter = false
        // debugger
        if(str == 'Home'){
          this.$router.push({ name:'Home'})
        }
        if(str == 'Tutorial'){
          this.$router.push({ name:'Tutorial'})
        }
        if(str == 'About'){
          this.$router.push({ name:'About'})
        }
        
        this.drawer = false
      },

      searchHandle(){
        console.log('111',this.$route)
        this.drawer = false;
        if(this.$route.name == 'Search'){
          // 判断处于当前搜索页面，下面跳转会失效，主动调用搜索页面数据刷新方法
          this.inputSearch2 = this.inputSearch
          this.inputSearch = ''
          return
        }
        // if(this.inputSearch){
            this.$router.push({
              path: '/search',
              query: {
                  keyword: this.inputSearch,
              }
            })
            this.inputSearch = ''
        // }
      }
    }
}
</script>

<style lang="less">
  .main_header{
    background: #fff;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    .headContent {
      display: flex;
      // place-content: space-between;
      height: 100%;
      align-items: center;
      // padding:0 360px;
      .logo_info {
        display: flex;
        height: 100%;
        align-items: center;
        img {
          width: 48px;
          height: 48px;
        }
        span {
          margin-left: 10px;
          font-size: 32px;
        }
      }
      .head_tabs {
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-left:50px;
        height: 100%;
        span {
          display: block;
          height:100%;
          line-height: 80px;
          width:100px;
          cursor: pointer;
        }
        span:hover {
          color: #002BFF ;
        }
        .actives{
          color: #002BFF ;
        }
      }
      .head_Search {
        margin-left:auto;
        .inputSearch {
          width: 290px;
          height: 46px;
          line-height: 46px;
          border-radius: 8px;
          background: #eee;
        }
      }
      .mobileMenu {
        margin-left: auto;
      }
    }

   ::deep .el-drawer__body {
      padding: 0 !important;
    }
  }

  .contentBox {
    // margin-top:80px
  }
  
  .main_footer {
    height: 100px;
    background: #2F2E35;
    // padding:0 360px;
    margin-top:50px;
    .footer_content {
      display: flex;
      align-items: center;
      color: #fff;
      // font-size: 16px;
      height: 100px;
      // line-height: 100px;
      
      img {
        width: 48px;
        height: 48px;
        margin-right: 20px;
      }
    }
  }
  .positionB {
    position: absolute;
    bottom: 0;
  }

  .head_tabs2 {
    span {
      display: block;
      height: 30px;
      line-height: 30px;
      width: calc(100% - 20px);
      padding: 10px;
      border-bottom: 1px solid #eee;
    }
    .actives2 {
      background: #eee;
    }
    
  }

  .el-drawer__body {
    padding: 0 !important;
  }
 

  
</style>


<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
body,html{
  padding:0;
  margin:0;
  background: #F3F3F3 100%;
}
</style>
