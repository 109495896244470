<template>
    <div class="typeBox_item" v-for="(item,index) in instructRemList" :key="index">
        <div class="recommend_T">
            <span class="recommend_T_tip"></span>
            <span>{{item.title}}</span>
        </div>
        <div class="recommend_C"  v-for="(i,indexs) in item.data" :key="indexs" @click="openDetail(item.remType,i.uuid)">
            <span class="recommend_C_tip"></span>
            <span>{{i.name}}</span>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue';
import {instructRem} from '../api'
export default {
  name: 'Popular',
    data() {
      return {
      instructRemList:[],//推荐列表
      }
    },

    props:{
        sname:{
            type:String,
            default:""
        },
    },

    created(){
        this.getInstructRem();//获取推荐
    },

    methods:{
         //获取指令推荐
      getInstructRem(){
         let params = {
            common:{
                channel:'appstore',
                userId:'',
                other:{},
                os:'ios',
                subProductId:'104',
                terminal:'app',
                deviceId:'UUID_AB23D19E-A9AA-4637-B8DF-DA2C2DE34F7E',
                version:'1220'
            },
            business:{
              
            }
        }
        instructRem(params).then((result) => {
            if(result.code != '1'){
                this.$message.error(result.msg)
                return;
            }

            let arr = [];
            for(let i in result.data){
                if(result.data[i].remType == 2){
                    arr.push(result.data[i])
                }
            }
           
            this.instructRemList =  arr
        })
      },

        //点击推荐列表
        openDetail(remType,id){
            console.log('sname:',this.sname)
            //因为右侧推荐点击跳转详情页，如果详情页加载此推荐列表，点击不会跳转，所以执行刷新接口操作
            if(this.sname == 'detail'){
                this.$emit('upDateDetail',id)
                return 
            }

            if(remType == 1 || remType == 2){
                this.$router.push({
                    path: '/detail',
                    query: {
                        id: id,
                    }
                })
            }

            if(remType == 3){
                window.open(id,"_blank")
            }
        },

    }

}
</script>


<style lang="less" scoped>
.typeBox_item {
                background: #fff;
                text-align: left;
                padding: 10px;
                margin-bottom: 10px;
                .recommend_T {
                    display: flex;
                    font-size: 16px;
                    font-weight: bold;
                    .recommend_T_tip {
                        width: 8px;
                        height: 20px;
                        display: block;
                        background: #002BFF;
                        border-radius: 4px;
                        margin-right: 5px;
                        flex-shrink: 0;
                    }
                }
                .recommend_C:hover {
                    background: #eee;
                }
                .recommend_C {
                    display: flex;
                    font-size: 12px;
                    margin-top: 10px;
                    align-items: center;
                    width: calc(100% - 17px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    padding: 5px;
                    .recommend_C_tip {
                            display: block;
                            width: 12px;
                            height: 12px;
                            border: 1px solid #9F9F9F;
                            border-radius: 50%;
                            margin-right: 5px;
                            flex-shrink: 0;
                    }
                }

            }
</style>