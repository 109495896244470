<template>
    <div class="home_main">
         <!-- <img alt="banner" class="banner_img" src="../assets/banner.jpg"> -->

         <div class="pageNav">首页 > 教程与技巧</div>
         <div class="pageTitle">教程与技巧 </div>
         <div  class="home_content">
            <!-- 左侧快捷指令列表 -->
            <div class="tutorial_content_l" >
                <div class="tutorialBox" >
                    <div class="tutorialBox1">
                        <div v-for="(i,indexs) in videoList" :key="indexs" class="tutorialBox_item2">
                            <div class="tutorialBox_item_T" @click="seeVideo(i.videoWebCourse)">
                                <img :src="i.videoCover" alt="快捷指令教程">
                                <el-icon class="playIcon"><VideoPlay /></el-icon>
                                <!-- <video :poster="i.videoCover" :src="i.videoWebCourse" controls="controls" /> -->
                            </div>
                            <div class="tutorialBox_item_R">{{i.videoName}}</div>
                            <!-- <div class="tutorialBox_item_C">
                                <div class="tutorialBox_item_C_U">
                                    <img src="../assets/users.png" alt="">
                                    <span>{{i.author}}</span>
                                </div>
                                <div class="tutorialBox_item_C_U">
                                    <img src="../assets/watch.png" alt="">
                                    <span>{{i.aageview}}</span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div> 
                <div class="showMoreBox">
                    <span v-if="!nomoreFlag" @click="showMore" class="showMoreBtn">点击查看更多</span>
                    <span v-else class="noMoreTip">- 已加载全部 -</span>
                </div>
            </div>

            <!-- 右侧推荐分类列表 -->
            <div class="home_content_r hidden-xs-only">
                <!-- <div class="typeBox_item" v-for="(item,index) in recommend" :key="index">
                    <div class="recommend_T">
                        <span class="recommend_T_tip"></span>
                        <span>{{item.title}}</span>
                    </div>
                    <div class="recommend_C"  v-for="(i,indexs) in item.list" :key="indexs">
                        <span class="recommend_C_tip"></span>
                        <span>{{i}}</span>
                    </div>
                    
                </div> -->
                 <Popular sname="tutorial"/>
            </div>
         </div>


        <el-dialog
            title="预览视频"
            :width="600"
            v-model="viewVideo"
        >
            <video
                :src="this.videoUrl"
                width="500"
                autoplay="autoplay"
                controls="controls"
                :state="viewVideo"
                ref="vueRef"
            ></video>
        </el-dialog>

    </div>
</template>

<script>
import {ref} from 'vue';
import {instructCourse} from '../api';
import Popular from '@/components/popular.vue'
export default {
  name: 'Tutorial',
  components:{
    Popular
  },

    data(){
        return {
            videoList:[],
            pageNum:1,
            videoUrl:'',
            nomoreFlag:false,
            viewVideo:false
        }
    },

    created(){
        this.getVideoList(1)
    },

    methods:{
          //获取列表  
       getVideoList(pageNum){
        let params = {
            common:{
                channel:'appstore',
                userId:'',
                other:{},
                os:'ios',
                subProductId:'104',
                terminal:'app',
                deviceId:'UUID_AB23D19E-A9AA-4637-B8DF-DA2C2DE34F7E',
                version:'1220'
            },
            business:{
                page:pageNum
            }
        }
        instructCourse(params).then((result) => {
            console.log("result", result);
            if(result.code != '1'){
                this.$message.error(result.msg)
                return;
            }

            
            if(result.data.length <= 0){
                this.nomoreFlag = true;//显示没有更多了字段和状态 
                return;
            }
           this.nomoreFlag = false;
           //拼接已存在的数据，切换分类时清空
            this.videoList =  this.videoList.concat(result.data) 
         
        })
      }, 

      //点击加载更多
    showMore(){
        this.pageNum = this.pageNum + 1;
        this.getVideoList(this.pageNum) //拼接数据在此方法里处理
    },

       // 预览视频
        seeVideo(src){
            window.open(src,"_blank")
        // this.videoUrl = src
        // this.viewVideo = true
        // this.$refs.vueRef.play();//播放  
        },
    },
}
</script>

<style scoped lang="less">
.home_main {
    // padding: 0 360px;
    // .banner_img {
    //     width:100%;
    //     margin-top:20px
    // }
    .pageNav {
        padding: 20px 0;
        font-size: 12px;
        color: #707070 ;
        text-align: left;
    }
    .pageTitle {
        height: 68px;
        background: #fff;
        font-size: 20px;
        line-height: 68px;
        padding: 0 10px;
        text-align: left;
        margin-bottom: 15px;
    }
    .home_content {
        // margin-top:20px;
        display: flex;
        justify-content: space-between;
        .tutorial_content_l {
            // background: #fff;
            // width: 70%;
            text-align: left;
            // padding: 10px;
                .tutorialTitle {
                    display: flex;
                    
                    span {
                        margin-right:10px;
                        font-size: 16px;
                        padding:2px 3px;
                        cursor: pointer;
                    }
                    .activess {
                        background:#002BFF;
                        color:#fff;
                        border-radius: 5px;
                    }
                }
                .tutorialBox {
                    .tutorialBox1 {
                        display: flex;
                        flex-wrap: wrap;
                        // margin-top: 10px;
                        place-content: flex-start;
                        .tutorialBox_item2 {
                            padding:10px;
                            // border-radius: 20px;
                            // width: calc(25%  - 30px);
                            flex-shrink: 0;
                            color: #000;
                            margin-left: 10px;
                            margin-bottom: 10px;
                            background: #fff;
                            .tutorialBox_item_T {
                                display: flex;
                                align-items: center;
                                font-size: 15px;
                                position: relative;
                                img {
                                    width: 100%;
                                    // height:40px;
                                }
                                .playIcon {
                                    position: absolute;
                                    top: calc(50% - 25px);
                                    left: calc(50% - 25px);
                                    font-size: 50px;
                                    color: #fff;
                                }
                                span {
                                    padding:2px 5px;
                                    margin-right:10px
                                }
                            };
                            .tutorialBox_item_R {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                font-size: 14px;
                                margin-top: 10px;
                            };
                            .tutorialBox_item_C {
                                font-size: 12px;
                                margin-top: 20px;
                                display: flex;
                                place-content: space-between;
                                .tutorialBox_item_C_U {
                                    display: flex;
                                    align-items: center;
                                    img {
                                        width: 12px;
                                        height:12px;
                                        margin-right:5px
                                    }
                                }
                            };
                        }
                    }    
                }
                .showMoreBox {
                    text-align: center;
                    margin: 30px 0;
                    .showMoreBtn {
                        display: block;
                        color: #002BFF;
                        width: 260px;
                        height: 40px;
                        line-height: 40px;
                        font-size: 15px;
                        border: 1px solid #002BFF;
                        border-radius: 12px;
                        margin: 0 auto;
                        cursor: pointer;
                    }
                    .noMoreTip {
                        color:#ccc;
                        font-size: 14px;
                    }
                }
        }
        .home_content_r {
            width: calc(30% - 20px);
            .typeBox_item {
                background: #fff;
                text-align: left;
                padding: 10px;
                margin-bottom: 10px;
                .recommend_T {
                    display: flex;
                    font-size: 16px;
                    font-weight: bold;
                    .recommend_T_tip {
                        width: 8px;
                        height: 20px;
                        display: block;
                        background: #002BFF;
                        border-radius: 4px;
                        margin-right: 5px;
                        flex-shrink: 0;
                    }
                }
                .recommend_C {
                    display: flex;
                    font-size: 12px;
                    margin-top: 10px;
                    align-items: center;
                    width: calc(100% - 17px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .recommend_C_tip {
                            display: block;
                            width: 12px;
                            height: 12px;
                            border: 1px solid #9F9F9F;
                            border-radius: 50%;
                            margin-right: 5px;
                            flex-shrink: 0;
                    }
                }

            }
        }
    }
}
</style>