<template>
    <div class="home_main">
         <div class="pageNav">首页 > 关于我们</div>
         <div class="pageTitle">关于我们 </div>
         <div  class="home_content">
            <!-- 左侧快捷指令列表 -->
            <div class="tutorial_content_l" >
                <div class="codeBox" >
                    <!-- <div class="tutorialBox1"> -->
                        <!-- <div v-for="(i,indexs) in instruct" :key="indexs" class="tutorialBox_item2">
                            <div class="tutorialBox_item_T">
                                <img src="../assets/bank-card.png" alt="">
                            </div>
                            <div class="tutorialBox_item_R">{{i.remarks}}</div>
                            <div class="tutorialBox_item_C">
                                <div class="tutorialBox_item_C_U">
                                    <img src="../assets/users.png" alt="">
                                    <span>{{i.author}}</span>
                                </div>
                                <div class="tutorialBox_item_C_U">
                                    <img src="../assets/watch.png" alt="">
                                    <span>{{i.aageview}}</span>
                                </div>
                            </div>
                        </div> -->
                    <!-- </div> -->
                    <div class="codeInfo">
                        <span>桌面APP下载</span>
                        <img @click="dialogTableVisible = true" src="../assets/appImg.png" alt="">
                    </div>
                </div> 
            </div>

            <!-- 右侧推荐分类列表 -->
            <div class="home_content_r hidden-xs-only">
                <!-- <div class="typeBox_item" v-for="(item,index) in recommend" :key="index">
                    <div class="recommend_T">
                        <span class="recommend_T_tip"></span>
                        <span>{{item.title}}</span>
                    </div>
                    <div class="recommend_C"  v-for="(i,indexs) in item.list" :key="indexs">
                        <span class="recommend_C_tip"></span>
                        <span>{{i}}</span>
                    </div>
                    
                </div> -->
                <Popular sname="about"/>
            </div>
         </div>
    </div>

    <el-dialog v-model="dialogTableVisible" title="" class="detailDialog">
        <div class="dialogImg">
            <img src="../assets/appImg.png" alt="唯美指令">
        </div>
    </el-dialog>
</template>

<script>
import {ref} from 'vue';
import Popular from '@/components/popular.vue'
export default {
  name: 'About',
  components:{
    Popular
  },
    data(){
        return {
            dialogTableVisible:false
        }
    },
}
</script>

<style scoped lang="less">
.home_main {
    // padding: 0 360px;
    // .banner_img {
    //     width:100%;
    //     margin-top:20px
    // }
    .pageNav {
        padding: 20px 0;
        font-size: 12px;
        color: #707070 ;
        text-align: left;
    }
    .pageTitle {
        height: 68px;
        background: #fff;
        font-size: 20px;
        line-height: 68px;
        padding: 0 10px;
        text-align: left;
        margin-bottom: 15px;
    }
    .home_content {
        // margin-top:20px;
        display: flex;
        justify-content: space-between;
        .tutorial_content_l {
            width: 100%;
            // text-align: left;
            .codeBox {
                background: #fff;
                .codeInfo {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 60px 0;
                    font-size: 16px;
                    img {
                        width: 110px;
                        height: 110px;
                        margin-top: 10px
                    }
                }
            }
   
        }
        .home_content_r {
            width: calc(30% - 20px);
            .typeBox_item {
                background: #fff;
                text-align: left;
                padding: 10px;
                margin-bottom: 10px;
                .recommend_T {
                    display: flex;
                    font-size: 16px;
                    font-weight: bold;
                    .recommend_T_tip {
                        width: 8px;
                        height: 20px;
                        display: block;
                        background: #002BFF;
                        border-radius: 4px;
                        margin-right: 5px;
                        flex-shrink: 0;
                    }
                }
                .recommend_C {
                    display: flex;
                    font-size: 12px;
                    margin-top: 10px;
                    align-items: center;
                    width: calc(100% - 17px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .recommend_C_tip {
                            display: block;
                            width: 12px;
                            height: 12px;
                            border: 1px solid #9F9F9F;
                            border-radius: 50%;
                            margin-right: 5px;
                            flex-shrink: 0;
                    }
                }

            }
        }
    }
}
.detailDialog {
    .dialogImg {
        img {
            width: 100%;
        }
    }
}

</style>