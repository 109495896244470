<template>
    <div class="home_main">
         <img alt="banner" class="banner_img" src="../assets/home_banner.png">
         <div  class="home_content">
            <!-- 左侧快捷指令列表 -->
            <div class="home_content_l" >
               <div class="tutorialTitle">
                    <span v-for="(item,index) in instructType" :key="index" :class="item.active?'activess':''" @click="activeClick(index,item.id)">{{item.name}}</span>
                </div> 
                <div class="tutorialBox" >
                    <div class="tutorialBox1">
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="i.tags"
                            placement="top-start"
                            v-for="(i,indexs) in instructList" :key="indexs"
                        >
                        <div :style="i.bgStyle" @click="toDetail(i.uuid)" class="tutorialBox_item">
                            <div class="tutorialBox_item_T">
                                <!-- <img src="../assets/bank-card.png" alt=""> -->
                                <span>{{i.name}}</span>
                            </div>
                            <div class="tutorialBox_item_R">{{i.title}}</div>
                            <div class="tutorialBox_item_C">
                                <div class="tutorialBox_item_C_U">
                                    <img :src="i.userInfo.userHead" alt="">
                                    <span>{{i.userInfo.userName}}</span>
                                </div>
                                <div class="tutorialBox_item_C_U">
                                    <img src="../assets/watch.png" alt="">
                                    <span>{{i.aageview}}</span>
                                </div>
                            </div>
                        </div>
                        </el-tooltip>
                    </div>
                </div> 
                <div class="showMoreBox">
                    <span v-if="!nomoreFlag" @click="showMore" class="showMoreBtn">点击查看更多</span>
                    <span v-else class="noMoreTip">- 已加载全部 -</span>
                </div>
            </div>

            <!-- 右侧推荐分类列表 -->
            <div class="home_content_r hidden-xs-only" >
                <div class="typeBox_item" v-for="(item,index) in instructRemList" :key="index">
                    <div class="recommend_T">
                        <span class="recommend_T_tip"></span>
                        <span>{{item.title}}</span>
                    </div>
                    <div class="recommend_C"  v-for="(i,indexs) in item.data" :key="indexs" @click="openDetail(item.remType,i.uuid)">
                        <span class="recommend_C_tip"></span>
                        <span>{{i.name}}</span>
                    </div>
                    
                </div>
            </div>
         </div>
    </div>
</template>

<script>
import {ref} from 'vue';
import {instructList,instructTag,instructRem} from '../api'
export default {
  name: 'Home',
    data() {
      return {
      instructList:[],//指令展示列表
      instructType:[],//指令分类列表
      instructRemList:[],//推荐列表
      defaultActive:0,
      dicId:0, //默认分类id
      defaultPage:1,
      nomoreFlag:false,//点击更多按钮显示状态
      }
    },

    created(){
      
    },
    mounted(){
      this.getType();//获取分类
      this.getInstructRem();//获取推荐
    },
    beforeDestroy: function () {
     
    },
    methods: {
      //获取分类
      async getType(){
        let params = {
            common:{
                channel:'appstore',
                userId:'',
                other:{},
                os:'ios',
                subProductId:'104',
                terminal:'app',
                deviceId:'UUID_AB23D19E-A9AA-4637-B8DF-DA2C2DE34F7E',
                version:'1220'
            },
            business:{
                dicType:34000
            }
        }
        instructTag(params).then((result) => {
            if(result.code != '1'){
                this.$message.error(result.msg)
                return;
            }
             if(result.data.length > 0){
                result.data[0].active = true;
                this.dicId =  result.data[0].id
                this.getList(result.data[0].id,1)
            }
            this.instructType = result.data
        })
        
      },

      //获取列表  
      async getList(tag,pageNum){
        let params = {
            common:{
                channel:'appstore',
                userId:'',
                other:{},
                os:'ios',
                subProductId:'104',
                terminal:'app',
                deviceId:'UUID_AB23D19E-A9AA-4637-B8DF-DA2C2DE34F7E',
                version:'1220'
            },
            business:{
                page:pageNum,
                keyword:'',
                dicId:tag
            }
        }
        instructList(params).then((result) => {
            if(result.code != '1'){
                this.$message.error(result.msg)
                return;
            }

            if(result.data.length <= 0){
                this.nomoreFlag = true;//显示没有更多了字段和状态 
                return;
            }
           this.nomoreFlag = false;
           //拼接已存在的数据，切换分类时清空
            this.instructList =  this.instructList.concat(result.data) 
        })
        
      }, 

      //获取指令推荐
      getInstructRem(){
         let params = {
            common:{
                channel:'appstore',
                userId:'',
                other:{},
                os:'ios',
                subProductId:'104',
                terminal:'app',
                deviceId:'UUID_AB23D19E-A9AA-4637-B8DF-DA2C2DE34F7E',
                version:'1220'
            },
            business:{
              
            }
        }
        instructRem(params).then((result) => {
            console.log("result", result);
            if(result.code != '1'){
                this.$message.error(result.msg)
                return;
            }
           
            this.instructRemList = result.data
        })
      },

    //切换分类
    activeClick(num,id){
        //改变点击选中的节点的样式
        for (let i = 0; i < this.instructType.length; i++) {
            this.instructType[i].active = false
            this.instructType[num].active = true
        }

        //如果点击标签不是当前标签，处理如下
        if(id !=  this.dicId){
            this.defaultPage = 1; //更新初始分页
            this.instructList = []; //清空旧的分类下的数据，
            this.getList(id,1)  //更新分类列表
            this.dicId = id; //更新为当前分类id
        }
    },

    //点击加载更多
    showMore(){
        this.defaultPage = this.defaultPage + 1;
        this.getList(this.dicId,this.defaultPage) //拼接数据在此方法里处理
    },

    //点击跳转详情
    toDetail(uuid){
        this.$router.push({
            path: '/detail',
            query: {
                id: uuid,
            }
        })
    },

    //点击推荐列表
    openDetail(remType,id){
        if(remType == 1 || remType == 2){
            this.toDetail(id)
        }

        if(remType == 3){
            window.open(id,"_blank")
        }
    },
      
    }


}
</script>

<style scoped lang="less">
.home_main {
    // padding: 0 360px;
    .banner_img {
        width:100%;
        margin-top:20px;
        margin-bottom: 15px
    }
    .home_content {
        // margin-top:20px;
        display: flex;
        justify-content: space-between;
        .home_content_l {
            background: #fff;
            // width: 64%;
            text-align: left;
            padding: 10px;
                .tutorialTitle {
                    display: flex;
                    flex-wrap: wrap;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #eee;
                    span {
                        margin-right:10px;
                        font-size: 16px;
                        padding:2px 3px;
                        cursor: pointer;
                    }
                    .activess {
                        background:#002BFF;
                        color:#fff;
                        border-radius: 5px;
                    }
                }
                .tutorialBox {
                    width: 100%;
                    .tutorialBox1 {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 10px;
                        place-content: flex-start;
                        .tutorialBox_item {
                            padding:10px;
                            border-radius: 20px;
                            // width: 28%;
                            flex-shrink: 0;
                            color: #fff;
                            margin-top: 10px;
                            margin-left: 1.5%;
                            .tutorialBox_item_T {
                                display: flex;
                                align-items: center;
                                font-size: 15px;
                                
                                img {
                                    width: 40px;
                                    height:40px;
                                }
                                span {
                                    padding:2px 5px;
                                    margin-right:10px;
                                    font-weight: 600;
                                }
                            };
                            .tutorialBox_item_R {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                font-size: 12px;
                                margin: 10px;
                                text-align: left
                            };
                            .tutorialBox_item_C {
                                font-size: 12px;
                                margin-top: 5px;
                                display: flex;
                                place-content: space-between;
                                .tutorialBox_item_C_U {
                                    display: flex;
                                    align-items: center;
                                    img {
                                        width: 12px;
                                        height:12px;
                                        margin-right:5px;
                                        border-radius: 50%;
                                    }
                                }
                            };
                        }
                    }    
                }
                .showMoreBox {
                    text-align: center;
                    margin: 30px 0;
                    .showMoreBtn {
                        display: block;
                        color: #002BFF;
                        width: 260px;
                        height: 40px;
                        line-height: 40px;
                        font-size: 15px;
                        border: 1px solid #002BFF;
                        border-radius: 12px;
                        margin: 0 auto;
                        cursor: pointer;
                    }
                    .noMoreTip {
                        color:#ccc;
                        font-size: 14px;
                    }
                }
        }
        .home_content_r {
            width: calc(36% - 40px);
            .typeBox_item {
                background: #fff;
                text-align: left;
                padding: 10px;
                margin-bottom: 10px;
                .recommend_T {
                    display: flex;
                    font-size: 16px;
                    font-weight: bold;
                    .recommend_T_tip {
                        width: 8px;
                        height: 20px;
                        display: block;
                        background: #002BFF;
                        border-radius: 4px;
                        margin-right: 5px;
                        flex-shrink: 0;
                    }
                }
                .recommend_C:hover {
                    background: #eee;
                }
                .recommend_C {
                    display: flex;
                    font-size: 12px;
                    margin-top: 10px;
                    align-items: center;
                    width: calc(100% - 17px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    padding: 5px;
                    .recommend_C_tip {
                            display: block;
                            width: 12px;
                            height: 12px;
                            border: 1px solid #9F9F9F;
                            border-radius: 50%;
                            margin-right: 5px;
                            flex-shrink: 0;
                    }
                }

            }
        }
    }
}
</style>